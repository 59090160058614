<template>
  <div>
    <page-header sticky>
      Dashboard
    </page-header>

    <div class="summary-body">
      <!-- <header>
        <h3 v-if="client.name">
          Selected client: {{ client.name }}
        </h3>
        <h3 v-else>
          <router-link :to="{ name: 'patientSelect' }">
            Select client here
          </router-link>
        </h3>
      </header> -->
      <v-container
        class="summary-2x-grid py-7"
        fluid
      >
        <card-summary
          image="card-reminders.svg"
          :card-header="'Total Reminders'"
          :number-header="totalReminders"
          :rate-change="totalRemindersChange"
          link-target="summary-reminders"
        />
        <card-summary 
          image="card-adherence.svg"
          :card-header="`${overall}Adherence Rate`"
          :number-header="adherenceRate + '%'"
          :rate-change="adherenceRateChange"
        />
        <card-summary
          v-if="!haveChosenClient"
          image="card-clients.svg"
          :card-header="'Total Clients'"
          :number-header="totalClients"
          :rate-change="totalClientsChange"
          link-target="summary-clients"
        />
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import moment from '@/plugins/moment'

import CardSummary from "@/components/dashboard/CardSummary.vue"
import PageHeader from '@/components/dashboard/PageHeader'
import { 
  getPercentageChange,
  calculateAdherenceRate,
  getDaysSinceDocument, 
  getCompleteAndSent
} from '@/kit/helpers/summaryFunctions.js'
// import review from '@/router/middleware/review'
// import { mdiConsoleNetworkOutline } from '@mdi/js'

const DAYS_SINCE = {
  TODAY: 0,
  SEVEN_DAYS_AGO: 7,
  FOURTEEN_DAYS_AGO: 14
}

// This value is used often and is what's displayed when the data is loading
// IDEA: Use spinners on the cards when data is loading
const emptyPlaceholder = '-'

export default {
  name: 'Summary',
  components: {
    CardSummary,
    PageHeader
  },
  data() {
    return {
      totalReminders: emptyPlaceholder,
      adherenceRate: emptyPlaceholder,
      totalClients: emptyPlaceholder,
      totalRemindersChange: 0,
      adherenceRateChange: 0,
      totalClientsChange: 0, 
    }
  },
  computed: {
    ...mapGetters('authentication', ['user', 'isProvider', 'providerTitle', 'isIntercomLaunched']),
    ...mapGetters('clients', ['client', 'haveChosenClient']),
    // Indicates whose adherence rate is being displayed (otherwise it's confusing)
    overall() {
      return this.haveChosenClient ? /*'Patient\'s '*/ '' : 'Overall '  // Mind trailing spaces
    },
    patients() {
      return this.haveChosenClient ? /*'Patient\'s '*/ '' : '' // Trailing spaces
    },
    summaryHeader() {
      if (!this.haveChosenClient)
        return 'Summary of activities'
      
      if (!this.client?.name?.trim().length)
        return 'Summary of activities for client'
      
      return `Summary of activities for ${this.client.name.trim()}`
    }
  },
  watch: {
    async 'client.id'() {
      await this.refreshTiles()
    }
  },
  async created() {
    await this.refreshTiles()
  },
  mounted() {
    if (!this.isIntercomLaunched) {
      this.intercomBootUp()
    }
  },
  methods: {
    ...mapActions('authentication', ['intercomBootUp']),
    async refreshTiles() {
      if (!this.user) {
        // Caught when user logs out - edge case but causes 
        throw new Error('User is not signed in')
      }

      // Reset stats in case they've been set already
      // There's possibly a cleaner way to do this (see initialization)
      this.totalReminders = emptyPlaceholder
      this.adherenceRate = emptyPlaceholder
      this.totalClients = emptyPlaceholder
      this.totalRemindersChange = 0
      this.adherenceRateChange = 0
      this.totalClientsChange = 0

      // User object in db/vuex has a counter built-in that increments for each client
      this.totalClients = this.user.countClients ?? 0

      /**
       * Get the number of reminders that have been created from
       * the practice section to be excluded from the count
       * and adherence rate
       */
      let countPracticeReminders = 0
      try {
        let reviews
        if (this.haveChosenClient) {
          // Count for just selected patient
          reviews = await this.$firebase.firestore().collection('reviews')
            .where('createdBy', '==', this.user.id)
            .where('clientId', '==', this.client.id)
            .get()
        } else {
          // Count for all patients
          reviews = await this.$firebase.firestore().collection('reviews')
            .where('createdBy', '==', this.user.id)
            .get()
        }

        reviews.forEach(doc => {
          const review = doc.data()
          if (review.practice) {
            ++countPracticeReminders
          }
        })
      } catch (e) {
        console.error('Error getting number of practice reminders')
        throw e
      }

      /**
       * We're going to pull the latest data for totalReminders and totalClients.
       * totalReminders will vary depending on if the user has a patient selected or not.
       *  
       * See schedulesOnWrite.ts in functions. When a schedule is updated (i.e., reminders sent)-
       * the countReminders value is incremented on the user doc
       */
      try {
        if (!this.haveChosenClient) {
          const user = (await this.$firebase.firestore()
            .doc(`users/${this.user.id}`)
            .get())
            .data()
  
          this.totalReminders = user.countReminders - countPracticeReminders
          this.totalClients = user.countClients ?? 0
        } else {
          // totalClients is irrelevant in this state, we don't show that tile when a patient is selected
          // ! This could be an expensive query depending on the number of schedules (should be low enough though)
          const userSchedules = await this.$firebase.firestore().collection('schedules')
            .where('createdBy', '==', this.user.id)
            .where('clientId', '==', this.client.id)
            .get()
          
          this.totalReminders = userSchedules.size - countPracticeReminders
        }
        
        // Strictly check for undefined, this isn't equivalent functionally to "!this.totalReminders"
        if (this.totalReminders === undefined || isNaN(this.totalReminders)) {
          this.totalReminders = 0
        }
      } catch (e) {
        console.error('Could not load total reminders or total clients')
        throw e
      }

      /**
       * Get the adherance rate by getting the amount of progress=>=50
       * from reviews/sessions/[]/progress collection
       * 0 = not started, 1 = in progress, >=50 = complete
       * adherence rate = complete reminders / total reminders (sent) * 100
       */
      try {
        let reviews

        if (this.haveChosenClient) {
          // Load reviews for this patient only
          reviews = await this.$firebase.firestore().collection('reviews')
            .where('createdBy', '==', this.user.id)
            .where('clientId', '==', this.client.id)
            .get()
        } else {
          // Counting total reviews for the user
          reviews = await this.$firebase.firestore().collection('reviews')
            .where('createdBy', '==', this.user.id)
            .get()
        }

        // let totalCompleted = 0
        // let totalSent = 0
        // reviews.docs.forEach(doc => {
        //   totalCompleted += doc.data().sessions.filter(session => {
        //     totalSent += 1
        //     return !!session.completed && session.progress >= 50
        //   }).length
        // })  
        
        const { complete, sent } = getCompleteAndSent(reviews.docs)

        this.adherenceRate = Math.round(calculateAdherenceRate(complete, sent))
        // this.adherenceRate = Math.round(totalCompleted / totalSent * 100)
      } catch (e) {
        console.error('Error calculating adherence rate')
        throw e
      }

      // Query reviews before the next two steps because the snapshot will be required in both
      let reviews

      if (this.haveChosenClient) {
        // Load reviews for this patient only
        reviews = await this.$firebase.firestore().collection('reviews')
          .where('createdBy', '==', this.user.id)
          .where('clientId', '==', this.client.id)
          .get()
      } else {
        // Counting total reviews for the user
        reviews = await this.$firebase.firestore().collection('reviews')
          .where('createdBy', '==', this.user.id)
          .get()
      }

      /**
       * Get the change in adherence rate by comparing the past 7 days to 
       * the 7 days before from reviews/sessions/progress
       * Option 1: Get the adherence rate from the past 7 days, past 7 days
       *           before that, and compare
       * Option 2: Get the current adherence rate and compare it to what the
       *           adherence rate was 7 days ago
       * Currently using option 2
       * adherence rate change = getPercentChange(adherence rate 7 days ago, current adherence rate)
       */
      const reviews7DAgo = reviews.docs?.filter(review => {
        const daysSinceReminder = getDaysSinceDocument(review.data().schedule?.createdAt)
        return (daysSinceReminder >= DAYS_SINCE.TODAY && daysSinceReminder <= DAYS_SINCE.SEVEN_DAYS_AGO)
      }) ?? []

      // Look at completed and sent sessions a week ago
      const weekAgo = moment().subtract(7, 'days').toDate()
      const { complete, sent } = getCompleteAndSent(reviews7DAgo, weekAgo)
      const adherenceRate7DAgo = Math.round(calculateAdherenceRate(complete, sent))
      
      this.adherenceRateChange = Math.round(getPercentageChange(adherenceRate7DAgo, this.adherenceRate))


      /**
       * Get the change in total reminders by comparing the past 7 days to 
       * the 7 days before from reviews/schedule/createdAt
       * total reminders change = getPercentChange(change 14-8 days ago, change 7-0 days ago)
       */
      let countTotalRemindersPast7Days = 0
      let countTotalReminders7DaysBefore = 0
      let newRemindersRatePast7Days = 0
      let newRemindersRate7DaysBefore = 0
      reviews.forEach(doc => {
        let daysSinceReminder = getDaysSinceDocument(doc.data().schedule?.createdAt)
        if (daysSinceReminder >= DAYS_SINCE.TODAY && daysSinceReminder <= DAYS_SINCE.SEVEN_DAYS_AGO) {
          ++countTotalRemindersPast7Days
        }
        if (daysSinceReminder > DAYS_SINCE.SEVEN_DAYS_AGO && daysSinceReminder <= DAYS_SINCE.FOURTEEN_DAYS_AGO) {
          ++countTotalReminders7DaysBefore
        }
      })
      newRemindersRate7DaysBefore = getPercentageChange(
        this.totalReminders - (countTotalRemindersPast7Days + countTotalReminders7DaysBefore), 
        this.totalReminders - countTotalRemindersPast7Days
      )
      newRemindersRatePast7Days = getPercentageChange(
        this.totalReminders - countTotalRemindersPast7Days, 
        this.totalReminders
      )
      this.totalRemindersChange = Math.round(getPercentageChange(newRemindersRate7DaysBefore, newRemindersRatePast7Days))

      /**
       * Get the change in total clients by comparing the current number of clients to
       * the number of clients 7 days ago
       * total clients change = getPercentChange(clients amount 7 days ago, clients amount today)
       */
      let countNewClientsPast7Days = 0
      const clients = await this.$firebase.firestore().collection(`users/${this.user.id}/clients`)
      .get()
      clients.forEach(client => {
        let daysSinceCreated = getDaysSinceDocument(client.data().createdAt)
        if (daysSinceCreated >= DAYS_SINCE.TODAY && daysSinceCreated <= DAYS_SINCE.SEVEN_DAYS_AGO) {
          ++countNewClientsPast7Days
        }
      })
      const countClients7DaysAgo = this.totalClients - countNewClientsPast7Days
      this.totalClientsChange = Math.round(getPercentageChange(countClients7DaysAgo, this.totalClients))
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  &-body {
    font-size: 16px;
    margin-top: 1rem;
    header {
      margin-bottom: 3rem;
    }
  }

  &-2x-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;

    @media (max-width: 799px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
